import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

function ExpModal(props) {
  const { open, title, body, onClose } = props;
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      setOpen(true);
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={true} >
          <div
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {body}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ExpModal.propTypes = {
  body: PropTypes.node,
  title: PropTypes.any,
  open: PropTypes.any,
  onClose: PropTypes.any,
};

export default ExpModal;
