import React from "react";
import "./contact.scss";
import { SocialData } from '../../constants';
import SMCard from "./sm-card/sm-card";
import {
  Grid,
} from '@material-ui/core';

function Contact() {
  return (
      <React.Fragment>
          <Grid container spacing={2}>
              {Object.keys(SocialData).map((media, index) => (
                  <Grid item key={index} xs={12} sm={6} lg={4} xl={3}>
                      <SMCard
                          icon={media}
                          handle={SocialData[media].handle}
                          profileLink={SocialData[media].profileLink}
                      />
                  </Grid>
              ))}
          </Grid>
      </React.Fragment>
  );
}

export default Contact;
