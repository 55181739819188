import React from 'react';
import './App.scss';
import { AppBar } from '../app-bar'
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

function App() {
  return (
    <Switch>
      <Redirect from='/home' to='/' />
      <Route path='/'>
        <AppBar />
      </Route>
    </Switch>
    
  );
}

export default App;
