import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const WorkExp = {
    medianetLead: {
        title: 'Module Lead - Web Apps Development @ media.net',
        body: (
            <List component="ul" aria-label="work exp">
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Confer with the business operations team to discuss, prioritize, and devise appropriate engineering solutions to deliver on requirements."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Take the responsibility as POC for various impactful and revenue yielding modules on the project."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Take ownership for prioritization, design, and implementation of various features."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Confer with different team modules to make sure releases are driven in cohesion."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Evaluate project code to ensure that it is valid, is properly structured, meets industry standards and is compatible with browsers, devices, or operating systems."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Mentor subordinates in understanding projects and requirements, and guide them through implementation and delivery."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li">
                    <ListItemText
                        primary="Evaluate new candidates on the basis of experience and skills as part of the interview panel for designated positions."
                        disableTypography
                    />
                </ListItem>
            </List>
        ),
    },
    medianet: {
        title: 'Senior Web App Developer @ media.net',
        body: (
            <List component="ul" aria-label="work exp">
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Confer with the business operations team to deliver on requirements in a fast paced environment."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Take ownership for end to end implementation of business logic for customer/client facing APIs and for other independent services."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Take ownership for development and delivery of front-end features on the customer facing web app."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Deploy, monitor and analyze performance of services and features utilizing Google’s Cloud Computing Services."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Perform or direct revision, repair, or expansion of existing programs/code to increase operating efficiency or adapt to new requirements."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Evaluate code for owned modules to ensure that it is valid, is properly structured, meets industry standards and is compatible with browsers, devices, or operating systems."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Mentor subordinates in understanding projects and requirements."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li">
                    <ListItemText
                        primary="Evaluate new candidates on the basis of experience and skills as part of the interview panel for designated positions."
                        disableTypography
                    />
                </ListItem>
            </List>
        ),
    },
    freshworksSenior: {
        title: 'Senior Software Engineer @ Freshworks Inc.',
        body: (
            <List component="ul" aria-label="work exp">
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Build and release a contextual chat platform product called Freshconnect."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Take responsibility as a full stack developer for end to end feature developments and code ownership in the project."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Confer with management or development teams to prioritise needs, resolve conflicts, develop content criteria, or choose solutions."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Perform or direct revision, repair, or expansion of existing programs to increase operating efficiency or adapt to new requirements."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Identify problems uncovered by testing or customer feedback, and correct problems or refer problems to appropriate personnel for correction."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Evaluate code to ensure that it is valid, is properly structured, meets industry standards and is compatible with browsers, devices, or operating systems."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Recommend and implement performance improvements."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Train subordinates in programming and program coding."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li">
                    <ListItemText
                        primary="Maintain understanding of current web technologies or programming practices through continuing education, reading, or participation in workshops, or groups."
                        disableTypography
                    />
                </ListItem>
            </List>
        ),
    },
    freshworksJunior: {
        title: 'Software Engineer @ Freshworks Inc.',
        body: (
            <List component="ul" aria-label="work exp">
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Take responsibility as a full stack developer for end to end feature
            developments and code ownership in the project."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Lead front-end development for the project."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Perform revision, repair, or expansion of existing programs to
            increase operating efficiency or adapt to new requirements."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Identify problems uncovered by testing or customer feedback, and
            correct problems or refer problems to appropriate personnel for
            correction."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li">
                    <ListItemText
                        primary="Maintain understanding of current web technologies or programming
            practices through continuing education, reading, or participation in
            workshops, or groups."
                        disableTypography
                    />
                </ListItem>
            </List>
        ),
    },
    huawei: {
        title: 'Software Engineer @ Huawei Technologies India Private Ltd',
        body: (
            <List component="ul" aria-label="work exp">
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Correct errors by making appropriate changes and rechecking the program to ensure that the desired results are produced."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li">
                    <ListItemText
                        primary="Conduct trial runs of programs and software applications to be sure they will produce the desired information and that the instructions are correct."
                        disableTypography
                    />
                </ListItem>
            </List>
        ),
    },
    ericsson: {
        title: 'Software Engineering Intern @ Ericsson Global Services, India',
        body: (
            <List component="ul" aria-label="work exp">
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Automate processes using Java in the R&D department."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Compile and write documentation of program development and subsequent revisions, inserting comments in the coded instructions so others can understand the program."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li">
                    <ListItemText
                        primary="Support as Scrum Master for the team of interns"
                        disableTypography
                    />
                </ListItem>
            </List>
        ),
    },
    srm: {
        title: 'B.Tech. CSE @ SRM University, Kattankulathur',
        body: (
            <List component="ul" aria-label="work exp">
                <ListItem component="li" divider>
                    <ListItemText primary="9.26 GPA" disableTypography />
                </ListItem>
                <ListItem component="li" divider>
                    <ListItemText
                        primary="Created a plagiarism detector app for android. The app was a challenge
                        initially due to low processing abilities of mobile phones. Utilized the resulting
                        code to extend the app to a desktop version."
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li">
                    <ListItemText
                        primary="Created an app to compare DNA strings in an internship at IIT BHU under
                        then dept. HOD Prof. A.K. Agrawal. This became a seeding idea for the final year
                        plagiarism detector project."
                        disableTypography
                    />
                </ListItem>
            </List>
        ),
    },
    sunbeam: {
        title: 'High School @ Sunbeam Schools, Varanasi',
        body: (
            <List component="ul" aria-label="work exp">
                <ListItem component="li" divider>
                    <ListItemText
                        primary="12th : 86.2% [ Maths, Science, Computer Science (elective) ]"
                        disableTypography
                    />
                </ListItem>
                <ListItem component="li">
                    <ListItemText
                        primary="10th : 92.0% [ Maths, Science ]"
                        disableTypography
                    />
                </ListItem>
            </List>
        ),
    },
};

export default WorkExp;
