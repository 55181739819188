import React from 'react';
import './work.scss';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as EricssonIcon } from '../../images/ericsson.svg';
import { ReactComponent as HuaweiIcon } from '../../images/huawei.svg';
import Button from '@material-ui/core/Button';
import IsLargeScreen from '../../utils';
import ExpModal from '../exp-modal';
import { WorkExp } from '../../constants';
import Link from '@material-ui/core/Link';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((_theme) => ({
    timelineConnectorLong: {
        minHeight: '100px',
    },
    timelineConnectorShort: {
        minHeight: '70px',
    },
    timelineItem: {
        '&::before': {
            maxWidth: 0,
            padding: 0,
        },
    },
    roleButton: {
        fontSize: '0.8em',
        padding: '2px 10px',
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        marginTop: '5px',
    },
}));

function WorkExperience() {
    const classes = useStyles();
    const [openWorkModal, setOpenWorkModal] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState(null);
    const [modalBody, setModalBody] = React.useState(null);

    const handleClickOpenWorkModal = (company) => () => {
        setModalBody(WorkExp[company].body);
        setModalTitle(WorkExp[company].title);
        setOpenWorkModal(true);
    };

    const onModalClose = () => {
        setOpenWorkModal(false);
    };

    return (
        <React.Fragment>
            <ExpModal
                open={openWorkModal}
                title={modalTitle}
                body={modalBody}
                onClose={onModalClose}
            >
                {modalBody}
            </ExpModal>
            {IsLargeScreen() ? (
                <Timeline align="alternate" style={{ paddingBottom: '40px' }}>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">
                                01/2022
                            </Typography>
                            <Typography>media.net</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <Link
                                href="https://www.media.net/aboutus/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="medianet-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/medianet.png')}
                                        alt="media.net"
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorLong,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Button
                                id="medianet"
                                classes={{
                                    root: classes.roleButton,
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpenWorkModal(
                                    'medianetLead'
                                )}
                            >
                                Module Lead - Web Apps Development
                            </Button>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">
                                10/2019
                            </Typography>
                            <Typography>media.net</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <Link
                                href="https://www.media.net/aboutus/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="medianet-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/medianet.png')}
                                        alt="media.net"
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorLong,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Button
                                id="medianet"
                                classes={{
                                    root: classes.roleButton,
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpenWorkModal('medianet')}
                            >
                                Senior Web Application Developer
                            </Button>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">
                                04/2019
                            </Typography>
                            <Typography>Freshworks Inc.</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <Link
                                href="https://www.freshworks.com/company/about/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="freshworks-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/freshworks.png')}
                                        alt="Freshworks Inc."
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorLong,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Button
                                classes={{
                                    root: classes.roleButton,
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpenWorkModal(
                                    'freshworksSenior'
                                )}
                            >
                                Senior Software Engineer
                            </Button>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">
                                02/2017
                            </Typography>
                            <Typography>Freshworks Inc.</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <Link
                                href="https://www.freshworks.com/company/about/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="freshworks-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/freshworks.png')}
                                        alt="Freshworks Inc."
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorLong,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Button
                                classes={{
                                    root: classes.roleButton,
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpenWorkModal(
                                    'freshworksJunior'
                                )}
                            >
                                Software Engineer
                            </Button>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">
                                06/2016
                            </Typography>
                            <Typography>
                                Huawei Technologies India Pvt Ltd
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <Link
                                href="https://www.huawei.com/en/corporate-information"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <HuaweiIcon className="huawei-icon" />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorLong,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Button
                                classes={{
                                    root: classes.roleButton,
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpenWorkModal('huawei')}
                            >
                                Software Engineer
                            </Button>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">
                                02/2016
                            </Typography>
                            <Typography>
                                Ericsson Global Services, India
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <Link
                                href="https://www.ericsson.com/en/about-us"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <EricssonIcon className="ericsson-icon" />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorLong,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Button
                                classes={{
                                    root: classes.roleButton,
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpenWorkModal('ericsson')}
                            >
                                Software Engineering Intern
                            </Button>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">2016</Typography>
                            <Typography>
                                SRM University, Kattankulathur
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <Link
                                href="https://www.srmist.edu.in/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="srm-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/srm-logo.png')}
                                        alt="SRM"
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorLong,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Button
                                classes={{
                                    root: classes.roleButton,
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpenWorkModal('srm')}
                            >
                                B.Tech C.S.E.
                            </Button>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">2011</Typography>
                            <Typography>Sunbeam Schools, Varanasi</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <Link
                                href="http://www.sunbeamschools.com/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="sunbeam-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/sunbeam.png')}
                                        alt="Sunbeam"
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Button
                                classes={{
                                    root: classes.roleButton,
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpenWorkModal('sunbeam')}
                            >
                                High School
                            </Button>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            ) : (
                <Timeline align="left">
                    <TimelineItem classes={{ root: classes.timelineItem }}>
                        <TimelineSeparator>
                            <Link
                                href="https://www.media.net/aboutus/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="medianet-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/medianet.png')}
                                        alt="media.net"
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorShort,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography color="textSecondary" display="inline">
                                01/2022 |{' '}
                            </Typography>
                            <Typography display="inline">media.net</Typography>
                            <div>
                                <Button
                                    classes={{
                                        root: classes.roleButton,
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickOpenWorkModal(
                                        'medianetLead'
                                    )}
                                >
                                    Module Lead - Web Apps Development
                                </Button>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem classes={{ root: classes.timelineItem }}>
                        <TimelineSeparator>
                            <Link
                                href="https://www.media.net/aboutus/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="medianet-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/medianet.png')}
                                        alt="media.net"
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorShort,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography color="textSecondary" display="inline">
                                10/2019 |{' '}
                            </Typography>
                            <Typography display="inline">media.net</Typography>
                            <div>
                                <Button
                                    classes={{
                                        root: classes.roleButton,
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickOpenWorkModal(
                                        'medianet'
                                    )}
                                >
                                    Senior Web Application Developer
                                </Button>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem classes={{ root: classes.timelineItem }}>
                        <TimelineSeparator>
                            <Link
                                href="https://www.freshworks.com/company/about/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="freshworks-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/freshworks.png')}
                                        alt="Freshworks Inc."
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorShort,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography color="textSecondary" display="inline">
                                04/2019
                            </Typography>
                            <div>
                                <Button
                                    classes={{
                                        root: classes.roleButton,
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickOpenWorkModal(
                                        'freshworksSenior'
                                    )}
                                >
                                    Senior Software Enginer
                                </Button>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem classes={{ root: classes.timelineItem }}>
                        <TimelineSeparator>
                            <Link
                                href="https://www.freshworks.com/company/about/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="freshworks-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/freshworks.png')}
                                        alt="Freshworks Inc."
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorShort,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography color="textSecondary" display="inline">
                                02/2017 |{' '}
                            </Typography>
                            <Typography display="inline">
                                Freshworks Inc.
                            </Typography>
                            <div>
                                <Button
                                    classes={{
                                        root: classes.roleButton,
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickOpenWorkModal(
                                        'freshworksJunior'
                                    )}
                                >
                                    Software Engineer
                                </Button>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem classes={{ root: classes.timelineItem }}>
                        <TimelineSeparator>
                            <Link
                                href="https://www.huawei.com/en/corporate-information"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <HuaweiIcon className="huawei-icon" />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorShort,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography color="textSecondary" display="inline">
                                06/2016 |{' '}
                            </Typography>
                            <Typography display="inline">
                                Huawei Technologies India Pvt Ltd
                            </Typography>
                            <div>
                                <Button
                                    classes={{
                                        root: classes.roleButton,
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickOpenWorkModal('huawei')}
                                >
                                    Software Engineer
                                </Button>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem classes={{ root: classes.timelineItem }}>
                        <TimelineSeparator>
                            <Link
                                href="https://www.ericsson.com/en/about-us"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <EricssonIcon className="ericsson-icon" />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorShort,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography color="textSecondary" display="inline">
                                02/2016 |{' '}
                            </Typography>
                            <Typography display="inline">
                                Ericsson Global Services, India
                            </Typography>
                            <div>
                                <Button
                                    classes={{
                                        root: classes.roleButton,
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickOpenWorkModal(
                                        'ericsson'
                                    )}
                                >
                                    Software Engineering Intern
                                </Button>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem classes={{ root: classes.timelineItem }}>
                        <TimelineSeparator>
                            <Link
                                href="https://www.srmist.edu.in/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="srm-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/srm-logo.png')}
                                        alt="SRM"
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector
                                classes={{
                                    root: classes.timelineConnectorShort,
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography color="textSecondary" display="inline">
                                2016 |{' '}
                            </Typography>
                            <Typography display="inline">
                                SRM University, Kattankulathur
                            </Typography>
                            <div>
                                <Button
                                    classes={{
                                        root: classes.roleButton,
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickOpenWorkModal('srm')}
                                >
                                    B.Tech C.S.E.
                                </Button>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem classes={{ root: classes.timelineItem }}>
                        <TimelineSeparator>
                            <Link
                                href="http://www.sunbeamschools.com/"
                                target="_blank"
                                underline="none"
                                className="noSelect"
                            >
                                <TimelineDot
                                    className="timeline-dot"
                                    color="inherit"
                                >
                                    <img
                                        className="sunbeam-icon"
                                        // eslint-disable-next-line no-undef
                                        src={require('../../images/sunbeam.png')}
                                        alt="Sunbeam"
                                    />
                                </TimelineDot>
                            </Link>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography color="textSecondary" display="inline">
                                2011 |{' '}
                            </Typography>
                            <Typography display="inline">
                                Sunbeam Schools, Varanasi
                            </Typography>
                            <div>
                                <Button
                                    classes={{
                                        root: classes.roleButton,
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickOpenWorkModal(
                                        'sunbeam'
                                    )}
                                >
                                    High School
                                </Button>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            )}
        </React.Fragment>
    );
}

export default WorkExperience;
