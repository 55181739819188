import React from 'react';
import PropTypes from "prop-types";
import {
    makeStyles,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActionArea,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        borderRadius: '6px',
        boxShadow: '0 4px 8px -4px rgba(0,0,0,0.3)',
        height: '4rem',
    },
    action: {
        display: 'flex',
        justifyContent: 'left',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        marginLeft: '1rem',
    },
    handleText: {
        fontSize: '1.6rem',
    },
    cover: {
        width: '4rem',
        background: '#ffffff',
        marginLeft: '2em',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

function SMCard(props) {
    const classes = useStyles();
    const { icon, handle, profileLink } = props;

    return (
        <Card className={classes.root}>
            <CardActionArea
                className={classes.action}
                href={icon === 'email' ? `mailto:${profileLink}` : profileLink}
                target="_blank"
            >
                <CardMedia
                    component="img"
                    className={classes.cover}
                    // eslint-disable-next-line no-undef
                    image={require(`../../../images/social/${icon}.svg`)}
                    title={handle}
                />
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography className={classes.handleText} variant="h4">{handle}</Typography>
                    </CardContent>
                </div>
            </CardActionArea>
        </Card>
    );
}

SMCard.propTypes = {
    icon: PropTypes.any,
    handle: PropTypes.any,
    profileLink: PropTypes.any,
};

export default SMCard;
