import React, { useEffect, useState } from 'react';
import './publications.scss';
import {
    makeStyles,
    Avatar,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Grid,
    // Badge,
    // withStyles,
    Link,
    CardActions,
    // CardActionArea,
    Divider,
    Button,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { ShortenText, ToText } from '../../utils';

const styles = makeStyles((muiBaseTheme) => ({
    blogHead: {
        margin: '0 5px',
    },
    blogFoot: {
        margin: '5px',
    },
    grid: {
        marginTop: '0px',
        minHeight: '391px',
    },
    card: {
        minWidth: 0,
        margin: 'auto',
        transition: '0.3s',
        minHeight: '391px',
        borderRadius: '.625rem!important',
        boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)',
        '&:hover': {
            boxShadow: '0 16px 40px -8.125px rgba(0,0,0,0.3)',
        },
    },
    media: {
        minHeight: '10rem',
        position: 'relative',
    },
    content: {
        textAlign: 'left',
        padding: muiBaseTheme.spacing(3),
    },
    divider: {
        margin: `${muiBaseTheme.spacing(2)}px 0`,
    },
    heading: {
        fontFamily: 'sans-serif',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#3d5170',
        '&::hover': {
            color: '#2b394f',
        },
    },
    subheading: {
        fontFamily: ('Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'),
        fontSize: '14px',
        lineHeight: 1.8,
    },
    avatar: {
        width: '3rem',
        height: '3rem',
        borderRadius: '50%',
        bottom: '-8rem',
        boxShadow:
            ' 0 0 0 0.125rem #fff, 0 0.1875rem 0.4375rem rgba(90,97,105,.5)',
        '&:not(:first-of-type)': {
            marginLeft: -muiBaseTheme.spacing(),
        },
    },
    avatarViewMore: {
        width: '8rem',
        height: '8rem',
        borderRadius: '50%',
    },
}));

// not needed for now
/* const StyledBadge = withStyles((theme) => ({
    badge: {
        display: 'flex',
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        bottom: '-7.75rem',

        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge); */

function Publications() {
    const classes = styles();
    const [mediumPosts, setMediumPosts] = useState([]);
    const [avatar, setAvatar] = useState('');
    const [profileLink, setProfileLink] = useState('');
    const [loading, setLoading] = useState(true);
    // const d = new Date();

    const getDisplayDate = (pubDate) => {
        const monthShortname = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        const splitDate = pubDate.split(' ');
        const date = splitDate[0];
        const splitMonth = date.split('-');
        const finalDate =
            monthShortname[Number(splitMonth[1] - 1)] +
            ' ' +
            splitMonth[2] +
            ',' +
            ' ' +
            splitMonth[0];
        return finalDate;
    };

    useEffect(() => {
        fetch(
            'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@kshitijsrv'
        )
            .then((res) => res.json())
            .then((data) => {
                // create two-dimensional array with 3 elements per inner array
                const freshworksRegex = /freshworks/gim;
                const regexToMatch = new RegExp(freshworksRegex);
                const avatar = data.feed.image;
                setAvatar(avatar);
                const profileLink = data.feed.link;
                setProfileLink(profileLink);
                const res = data.items; //This is an array with the content. No feed, no info about author etc..
                // this.setState({ avatar: avatar, profileLink: profileLink });
                const map = {};
                res.forEach((item) => {
                    if (item.categories.length > 0) {
                        if (map[item.title]) {
                            if (regexToMatch.test(item.description)) {
                                item['avatar'] = avatar; // push avatar inside the json
                                item['profilelink'] = profileLink; // push profile link inside the JSON
                                map[item.title] = item;
                            }
                        } else {
                            item['avatar'] = avatar; // push avatar inside the json
                            item['profilelink'] = profileLink; // push profile link inside the JSON
                            map[item.title] = item;
                        }
                    }
                });
                const posts = [];
                for (const item in map) {
                    posts.push(map[item]);
                }

                setMediumPosts(posts);
                setLoading(false);
            }).catch((err) => {
                alert(`An error occured while loading posts from medium.com\n${err}`);
            });
    }, []);

    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    className={classes.blogHead}
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item>
                        <Typography variant="h4">Medium</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            href="https://medium.com/@kshitijsrv"
                            target="_blank"
                        >
                            View More
                        </Button>
                    </Grid>
                </Grid>
                {mediumPosts.map((post, index) => (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        className={classes.grid}
                        key={index}
                    >
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.media}
                                image={post.thumbnail}
                            >
                                {/* {d.getHours() >= 5 && d.getHours() <= 22 ? (
                                    <StyledBadge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        variant="dot"
                                        style={{
                                            display: 'inline-block',
                                            alignItems: 'center',
                                            marginLeft: '1.5625rem',
                                        }}
                                    >
                                        <Avatar
                                            alt="kshitij srivastava"
                                            className={classes.avatar}
                                            src={post.avatar}
                                            component="a"
                                            varient="rounded"
                                            href={post.profilelink}
                                            target="_blank"
                                        />
                                    </StyledBadge>
                                ) : (
                                    <Avatar
                                        alt="kshitij srivastava"
                                        className={classes.avatar}
                                        src={post.avatar}
                                        component="a"
                                        style={{
                                            display: 'inline-block',
                                            alignItems: 'center',
                                            marginLeft: '1.5625rem',
                                        }}
                                        varient="rounded"
                                        href={post.profilelink}
                                        target="_blank"
                                    />
                                )} */}
                            </CardMedia>
                            <CardContent
                                className={classes.content}
                                style={{
                                    paddingTop: '2.1875rem',
                                    minHeight: '120px',
                                    paddingBottom: '0',
                                }}
                            >
                                <Typography
                                    className={classes.heading}
                                    variant={'h5'}
                                    gutterBottom
                                >
                                    <Link
                                        href={post.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="none"
                                    >
                                        {ShortenText(post.title, 0, 75)}
                                    </Link>
                                </Typography>
                                <Typography
                                    className={classes.subheading}
                                    variant="body1"
                                >
                                    {ShortenText(ToText(post.content), 0, 120) +
                                        '...'}
                                </Typography>
                            </CardContent>
                            <Divider className={classes.divider} />
                            <CardActions
                                style={{
                                    paddingLeft: '1.5625rem',
                                    paddingRight: '1.5625rem',
                                }}
                            >
                                <Grid>
                                    <Typography
                                        style={{
                                            fontSize: '0.8rem',
                                            color: '#212529',
                                        }}
                                        display="block"
                                        variant="body1"
                                        gutterBottom
                                    >
                                        <PersonIcon
                                            style={{
                                                verticalAlign: 'text-bottom',
                                                fontSize: '1rem',
                                            }}
                                        />{' '}
                                        {post.author}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography
                                        display="block"
                                        variant="body1"
                                        style={{
                                            fontSize: '0.8rem',
                                            color: '#868e96',
                                            display: 'inline-block',
                                        }}
                                        gutterBottom
                                    >
                                        <DateRangeIcon
                                            style={{
                                                verticalAlign: 'text-bottom',
                                                fontSize: '1rem',
                                            }}
                                        />{' '}
                                        {getDisplayDate(post.pubDate)}
                                    </Typography>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    className={classes.grid}
                >
                    <Card className={classes.card}>
                        <CardContent
                            className={classes.content}
                            style={{
                                // paddingTop: '2.1875rem',
                                minHeight: '391px',
                                paddingBottom: '19',
                            }}
                        >
                            <Grid
                                container
                                spacing={1}
                                direction="column"
                                alignItems="center"
                                justify="center"
                                style={{ minHeight: '391px' }}
                            >
                                <Grid item align="center">
                                    {
                                        loading ? (
                                            <div className='loader'>Loading...</div>
                                        ): (
                                            <Avatar
                                                alt="kshitij srivastava"
                                                className={classes.avatarViewMore}
                                                src={avatar}
                                                component="a"
                                                style={{
                                                    alignItems: 'center',
                                                }}
                                                varient="rounded"
                                                href={profileLink}
                                                target="_blank"
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item align="center">
                                    <Typography variant="h4">
                                        @kshitijsrv&apos;s posts
                                    </Typography>
                                </Grid>
                                <Grid item align="center">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        href={profileLink}
                                        target="_blank"
                                    >
                                        <Typography
                                            variant="h5"
                                            color="primary"
                                        >
                                            View all Posts
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Publications;
