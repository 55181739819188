/*
* Rate distribution -
* *****************
* 1: Active and strong
* 2: Active and well versed
* 3: Well versed
* 4: Acquainted
*/

const SkillRatings = {
    Backend: {
        'Languages/Libraries': [
            ['NodeJs', 1],
            ['Java', 2],
            ['Go', 3],
            ['Python', 3],
            ['C', 3],
            ['C++', 3],
        ],
        'Databases/ORM': [
            ['TypeORM', 2],
            ['postgreSQL', 2],
            ['GCP Datastore', 3],
            ['DynamoDB', 4],
            ['MongoDB', 4],
        ],
        Infrastructure: [
            ['GCP', 2],
            ['AWS', 3],
            ['Docker', 2],
            ['Kubernetes', 4],
        ],
    },
    Frontend: {
        'Languages/Libraries': [
            ['Javascript', 1],
            ['JQuery', 1],
            ['HTML', 1],
            ['CSS', 1],
        ],
        Frameworks: [
            ['React', 1],
            ['Ember (MVC)', 3],
            ['Ruby On Rails (MVC)', 3],
        ],
    },
    'Other Softwares / Tools': {
        'Version Control': [
            ['git', 1],
            ['Sourcetree', 1],
            ['svn', 4],
        ],
        'CI/CD': [
            ['CircleCI', 2],
            ['Travis CI', 4],
            ['Serverless', 4],
        ],
        Markup: [
            ['Markdown', 1],
            ['Emacs org-mode', 4],
        ],
    },
    'Other / Extra-professionals Skills': {
        'Soft Skills': [
            ['Communication'],
            ['Collaboration'],
            ['Critical Thinking'],
            ['Problem Solving'],
            ['Leadership'],
        ],
        'Extra-professionals': [
            ['Guitaring'],
            ['Sketching / Drawing'],
            ['Reading / Book-collecting'],
        ],
    },
};

export default SkillRatings;
