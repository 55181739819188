import React from "react";
import "./about.scss";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IsLargeScreen from "../../utils";
import { Grid } from "@material-ui/core";
import BgImage from "../../images/mountains.png";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    subTitleBody: {
        fontSize: '1.5em',
    },
    contentBox: {
        boxSizing: 'content-box',
    },
    gridContainer: {
        flexGrow: 1,
        '&.margin-xs': {
            marginTop: '-20px;',
        },
        '&.grid-bg-container': {
            backgroundImage: `url(${BgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '1.5rem',
        },
    },
    gridItem: {
        '&.space-margin': {
            margin: '1.5rem 0',
        },
    },
    introBannerImage: {
        '&.header-sm': {
            padding: '0',
        },
        '&.header-xl': {
            padding: '0 2rem',
        },
    },
    introBannerTitle: {
        '&.header-sm': {
            padding: '2rem 0 0 0',
        },
        '&.header-xl': {
            padding: '0 2rem',
        },
    },
    introHead: {
        flexGrow: 1,
        fontSize: '5rem',
    },
    introSubHead: {
        flexGrow: 1,
        fontSize: '3rem',
    },
    divider: {
        width: '100%',
    },
}));

function About() {
  const classes = useStyles();

  return (
      <Grid
          container
          direction="column"
          alignItems="center"
          className="grid-container-wrapper"
      >
          <Grid
              container
              item
              className={`grid-bg-container margin-xs`}
              direction={IsLargeScreen() ? 'row' : 'column'}
              justfiy="space-between"
              alignItems="center"
              classes={{
                  root: classes.gridContainer,
              }}
          >
              <Grid
                  item
                  xs={12}
                  md={6}
                  className={`intro-image-container header ${
                      IsLargeScreen() ? 'header-xl' : 'header-sm'
                  }`}
                  container
                  justfiy="center"
                  alignItems={IsLargeScreen() ? 'flex-end' : 'center'}
                  direction="column"
                  classes={{
                      root: classes.introBannerImage,
                  }}
              >
                  <img
                      className="intro-image"
                      // eslint-disable-next-line no-undef
                      src={require('../../images/ks.png')}
                      alt="kshitij"
                  />
              </Grid>
              <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  justfiy="center"
                  alignItems={IsLargeScreen() ? 'flex-start' : 'center'}
                  className={`intro-text-container ${
                      IsLargeScreen() ? 'header-xl' : 'header-sm'
                  }`}
                  direction="column"
                  classes={{
                      root: classes.introBannerTitle,
                  }}
              >
                  <Typography
                      variant="h1"
                      classes={{
                          root: classes.introHead,
                      }}
                  >
                      Hi there!
                  </Typography>
                  <Typography
                      variant="h3"
                      classes={{
                          root: classes.introSubHead,
                      }}
                  >
                      I am Kshitij
                  </Typography>
              </Grid>
          </Grid>
          <Grid
              container
              item
              direction={IsLargeScreen() ? 'row' : 'column'}
              justfiy="center"
              alignItems="center"
              classes={{
                  root: classes.gridContainer,
              }}
          >
              <Grid
                  item
                  xs={12}
                  container
                  justfiy="center"
                  alignItems={IsLargeScreen() ? 'center' : 'flex-start'}
                  className="desc-text-container space-margin"
                  direction="column"
                  classes={{
                      root: classes.gridItem,
                  }}
              >
                  <Typography
                      variant="h4"
                      classes={{
                          root: classes.title,
                      }}
                      gutterBottom
                  >
                      About me
                  </Typography>
                  <Typography
                      classes={{
                          root: classes.subTitleBody,
                      }}
                      variant="body1"
                      align={IsLargeScreen() ? 'center' : 'left'}
                      gutterBottom
                  >
                      Born and brought up in the small town of Varanasi a.k.a{' '}
                      <i>The Spiritual Capital of India</i> &mdash; I am{' '}
                      <u>
                          a science and technology enthusiast with a knack for
                          art and love for books
                      </u>
                      . I have always been fascinated by art and science alike,
                      which is probably also the reason that while I am a
                      Programmer by the day, I am also a guitarist and artist in
                      my leisure time. I am also a bibliophile and I think my
                      growing collection of books ( though not highly enviable
                      ), might just interest fiction and non-fiction lovers
                      alike.
                  </Typography>
              </Grid>
              <Divider classes={{ root: classes.divider }} />
              <Grid
                  item
                  xs={12}
                  container
                  justfiy="center"
                  alignItems={IsLargeScreen() ? 'center' : 'flex-start'}
                  className="desc-text-container space-margin"
                  direction="column"
                  classes={{
                      root: classes.gridItem,
                  }}
              >
                  <Typography
                      variant="h4"
                      classes={{
                          root: classes.title,
                      }}
                      gutterBottom
                  >
                      What I do
                  </Typography>
                  <Typography
                      classes={{
                          root: classes.subTitleBody,
                      }}
                      variant="body1"
                      align={IsLargeScreen() ? 'center' : 'left'}
                      gutterBottom
                  >
                      I am currently working full time as a{' '}
                      <u>Module Lead - Web Applications Development</u> at{' '}
                      <Link href="https://www.media.net" target="_blank">
                          media.net
                      </Link>
                      . With 6+ years of experience functioning as a full stack
                      developer, I am a hands-on software engineer adept with
                      platform development, particularly web-development
                      technologies , and skilled at technical leadership and
                      communication. Having served in service as well as product
                      companies, I have had a chance to work with and learn from
                      some outstanding individuals in the field of Information
                      Technology.
                  </Typography>
              </Grid>
              <Divider classes={{ root: classes.divider }} />
              <Grid
                  item
                  xs={12}
                  container
                  justfiy="center"
                  alignItems={IsLargeScreen() ? 'center' : 'flex-start'}
                  className="desc-text-container space-margin"
                  direction="column"
                  classes={{
                      root: classes.gridItem,
                  }}
              >
                  <Typography
                      variant="h4"
                      classes={{
                          root: classes.title,
                      }}
                      gutterBottom
                  >
                      About this website
                  </Typography>
                  <Typography
                      classes={{
                          root: classes.subTitleBody,
                      }}
                      variant="body1"
                      align={IsLargeScreen() ? 'center' : 'left'}
                      gutterBottom
                  >
                      This website is my attempt at a personal digital
                      portfolio. The 2020 pandemic driven boredom fueled my
                      creativity a little and my skills as a web developer came
                      quite handy then :) <br />
                      Built on{' '}
                      <Link href="https://reactjs.org/" target="_blank">
                          React JS
                      </Link>{' '}
                      with{' '}
                      <Link href="https://material-ui.com/" target="_blank">
                          Material UI components
                      </Link>{' '}
                      , it is statically hosted on{' '}
                      <Link href="https://firebase.google.com/" target="_blank">
                          Firebase
                      </Link>{' '}
                      with a simple CI/CD setup over{' '}
                      <Link href="https://circleci.com/" target="_blank">
                          circleci
                      </Link>{' '}
                      that builds and deploys the code on each commit pushed to
                      the main git branch. Firebase comes quite handy in this
                      regard due to ease of setup and it also provisions a free
                      SSL certificate to provide extra layer of security to the
                      website.
                  </Typography>
              </Grid>
          </Grid>
      </Grid>
  );
}

export default About;
