import React from "react";
import "./skills.scss";
import { SkillRatings } from '../../constants';
import { Grid, Card, Divider, Chip } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 420,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});

const useStyles = makeStyles({
    title: {
        marginTop: '0.5em',
    },
    pos: {
        marginBottom: 12,
    },
    card: {
        minWidth: 0,
        margin: 'auto',
        transition: '0.3s',
        borderRadius: '.625rem!important',
        boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)',
        '&:hover': {
            boxShadow: '0 16px 40px -8.125px rgba(0,0,0,0.3)',
        },
    },
    chip: {
        margin: '0.5em',
    },
    chip1: {
        background: '#FFAB91',
    },
    chip2: {
        background: '#FFE291',
    },
    chip3: {
        background: '#91FFAB',
    },
    chip4: {
        background: '#91E5FF',
    },
    chipDefault: {
        background: '#f5b6da',
    },
    chipColorDesc: {
        background: `#FFFFFF`,
    },
    colorCoding: {
        marginTop: '0.5em',
    },
    colorCodeContainer: {
        marginBottom: '15px',
    },
});

const SkillMap = {
  1: 'chip1',
  2: 'chip2',
  3: 'chip3',
  4: 'chip4',
  5: 'chipDefault',
};

const SkillCoding = {
  1: 'Active & Strong',
  2: 'Active & Well-versed',
  3: 'Well-versed',
  4: 'Acquainted',
};

function SkillSet(props) {
    const classes = useStyles();
    const { skillsArray } = props;
    return (
        <div>
            {skillsArray.map((skillObj, index) => (
                <Chip
                    key={index}
                    className={`${classes.chip} ${classes[SkillMap[skillObj[1] ? skillObj[1] : 5]]}`}
                    label={skillObj[0]}
                />
            ))}
        </div>
    );
}

SkillSet.propTypes = {
    skillsArray: PropTypes.array,
};

function SubCategoryList(props) {
  const classes = useStyles();
  const { subCategoryObj } = props;
  return Object.keys(subCategoryObj).map((subCategory, index) => (
      <div key={index}>
          <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
          >
              {subCategory}
          </Typography>
          <SkillSet skillsArray={subCategoryObj[subCategory]} />
      </div>
  ));
}

SubCategoryList.propTypes = {
    subCategoryObj: PropTypes.object.isRequired,
};

function ColorCode() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container item spacing={4} className={classes.colorCodeContainer}>
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Color Codes
                            </Typography>
                            <Divider className={classes.divider} />
                            <ThemeProvider theme={theme}>
                                <Grid
                                    container
                                    item
                                    className={classes.colorCoding}
                                    spacing={4}
                                >
                                    {Object.keys(SkillCoding).map(
                                        (colorCode, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <Chip
                                                    className={`${
                                                        classes.chip
                                                    } ${
                                                        classes[
                                                            SkillMap[colorCode]
                                                        ]
                                                    }`}
                                                    size={'small'}
                                                />
                                                <Typography component={'span'}>
                                                    {SkillCoding[colorCode]}
                                                </Typography>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </ThemeProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

function Skills() {
  const classes = useStyles();
  return (
      <React.Fragment>
          <Grid container direction={'row'}>
              <ColorCode />
              <Grid container item spacing={4}>
                  {Object.keys(SkillRatings).map((category, index) => (
                      <Grid
                          item
                          key={index}
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                      >
                          <Card className={classes.card}>
                              <CardContent>
                                  <Typography variant="h5" component="h2">
                                      {category}
                                  </Typography>
                                  <Divider className={classes.divider} />
                                  <SubCategoryList
                                      subCategoryObj={SkillRatings[category]}
                                  />
                              </CardContent>
                          </Card>
                      </Grid>
                  ))}
              </Grid>
          </Grid>
      </React.Fragment>
  );
}

export default Skills;
