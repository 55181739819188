import React, { useEffect } from "react";
import "./app-bar.scss";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../tab-panel";
import IsLargeScreen from "../../utils";
import IconButton from "@material-ui/core/IconButton";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import { ReactComponent as LinkedInIcon } from "../../images/social/linkedin.svg";
import { ReactComponent as EmailIcon } from "../../images/social/email.svg";
import WorkExperience from "../work";
import About from "../about";
import PropTypes from "prop-types";
import Contact from "../contact";
// import Education from "../education";
import Publications from "../publications";
// import Interests from "../interests";
import Skills from "../skills";
import Link from "@material-ui/core/Link";
// import MenuIcon from "@material-ui/icons/Menu";
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

const tabHeight = "64px";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    overflow: "visible",
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  appBarBottom: {
    top: "auto",
    bottom: 0,
  },
  offset: theme.mixins.toolbar,
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function TopBar(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const history = useHistory();
  const location = useLocation();

  const preventDefault = (event) => event.preventDefault();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
        case 0:
            history.replace('/about');
            break;
        case 1:
            history.replace('/workex');
            break;
        case 2:
            history.replace('/skills');
            break;
        case 3:
            history.replace('/publ');
            break;
        case 4:
            history.replace('/connect');
            break;
        default:
            history.replace('/about');
            break;
    }
  };

  useEffect(() => {
      switch (location.pathname) {
          case '/about':
              setValue(0);
              break;
          case '/workex':
              setValue(1);
              break;
          case '/skills':
              setValue(2);
              break;
          case '/publ':
              setValue(3);
              break;
          case '/connect':
              setValue(4);
              break;
          default:
              history.replace('/about');
              break;
      }
  }, [location, history]);

  return (
      <div className={classes.root}>
          {IsLargeScreen() ? (
              <div>
                  <AppBar position="fixed" color="default">
                      <Toolbar className="app-bar-tool-bar">
                          {/* <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton> */}
                          <Typography
                              variant="h5"
                              className={classes.title}
                              display="inline"
                              noWrap
                          >
                              Kshitij Srivastava
                          </Typography>
                          <Tabs
                              classes={{
                                  root: classes.tabsRoot,
                              }}
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                              scrollButtons="auto"
                              aria-label="scrollable auto tabs example"
                          >
                              <Tab
                                  label="About Me"
                                  {...a11yProps(0)}
                                  classes={{
                                      root: classes.tabsRoot,
                                  }}
                              />
                              <Tab
                                  label="Work & Education"
                                  {...a11yProps(1)}
                                  classes={{
                                      root: classes.tabsRoot,
                                  }}
                              />
                              <Tab
                                  label="Skills"
                                  {...a11yProps(2)}
                                  classes={{
                                      root: classes.tabsRoot,
                                  }}
                              />
                              <Tab
                                  label="Publications"
                                  {...a11yProps(3)}
                                  classes={{
                                      root: classes.tabsRoot,
                                  }}
                              />
                              {/* <Tab
                  label="Hobbies and Interests"
                  {...a11yProps(4)}
                  classes={{
                    root: classes.tabsRoot,
                  }}
                /> */}
                              <Tab
                                  label="Connect"
                                  {...a11yProps(4)}
                                  classes={{
                                      root: classes.tabsRoot,
                                  }}
                              />
                          </Tabs>
                          <div className="app-bar-buttons-container">
                              <IconButton
                                  aria-label="linkedin-icon"
                                  target="_blank"
                                  href="https://www.linkedin.com/in/kshitijsrv/"
                              >
                                  <LinkedInIcon className="linkedin-icon" />
                              </IconButton>
                              <IconButton
                                  aria-label="email-icon"
                                  target="_blank"
                                  href="mailto:kshitijsrv@gmail.com"
                              >
                                  <EmailIcon className="email-icon" />
                              </IconButton>
                          </div>
                      </Toolbar>
                  </AppBar>
                  <div className={classes.offset} />
              </div>
          ) : (
              <div>
                  <HideOnScroll {...props}>
                      <AppBar position="fixed" color="default">
                          <Toolbar className="app-bar-tool-bar">
                              {/* <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton> */}
                              <Typography
                                  variant="h5"
                                  className={classes.title}
                              >
                                  Kshitij Srivastava
                              </Typography>
                              <div className="app-bar-buttons-container">
                                  <IconButton
                                      aria-label="linkedin-icon"
                                      target="_blank"
                                      href="https://www.linkedin.com/in/kshitijsrv/"
                                  >
                                      <LinkedInIcon className="linkedin-icon" />
                                  </IconButton>
                                  <IconButton
                                      aria-label="email-icon"
                                      target="_blank"
                                      href="mailto:kshitijsrv@gmail.com"
                                  >
                                      <EmailIcon className="email-icon" />
                                  </IconButton>
                              </div>
                          </Toolbar>
                      </AppBar>
                  </HideOnScroll>
                  <div className={classes.offset} />
                  <AppBar
                      position="fixed"
                      color="default"
                      className={classes.appBarBottom}
                  >
                      <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                      >
                          <Tab label="About Me" {...a11yProps(0)} />
                          <Tab label="Work & Education" {...a11yProps(1)} />
                          <Tab label="Skills" {...a11yProps(2)} />
                          <Tab label="Publications" {...a11yProps(3)} />
                          {/* <Tab label="Hobbies and Interests" {...a11yProps(4)} /> */}
                          <Tab label="Connect" {...a11yProps(4)} />
                      </Tabs>
                      {/* <div className={classes.offset} /> */}
                  </AppBar>
              </div>
          )}
          <Switch>
              <Route path="/about">
                  <TabPanel value={value} index={0} className="tab-panel">
                      <About />
                  </TabPanel>
              </Route>
              <Route path="/workex">
                  <TabPanel value={value} index={1} className="tab-panel">
                      <WorkExperience />
                  </TabPanel>
              </Route>
              <Route path="/skills">
                  <TabPanel value={value} index={2} className="tab-panel">
                      <Skills />
                  </TabPanel>
              </Route>
              <Route path="/publ">
                  <TabPanel value={value} index={3} className="tab-panel">
                      <Publications />
                  </TabPanel>
              </Route>
              <Route path="/connect">
                  <TabPanel value={value} index={4} className="tab-panel">
                      <Contact />
                  </TabPanel>
              </Route>
              {/* <Route path="/about">
                  <TabPanel value={value} index={4} className="tab-panel">
                      <Interests />
                  </TabPanel>
              </Route> */}
          </Switch>
          <Typography
              variant="body2"
              align="center"
              className={IsLargeScreen() ? '' : 'copyright-padded'}
          >
              <Link href="#" onClick={preventDefault}>
                  © 2020 Kshitij Srivastava
              </Link>
          </Typography>
      </div>
  );
}

export default TopBar;
