const SocialData = {
    email: {
        baseColor: '#db534b',
        handle: 'Send mail',
        profileLink: 'kshitijsrv@gmail.com',
    },
    linkedin: {
        baseColor: '#0072ff',
        handle: '/kshitijsrv',
        profileLink: 'https://www.linkedin.com/in/kshitijsrv',
    },
    facebook: {
        baseColor: '#1877f2',
        handle: '/kshitij.srv',
        profileLink: 'https://www.facebook.com/kshitij.srv',
    },
    medium: {
        baseColor: '#000000',
        handle: '/@kshitijsrv',
        profileLink: 'https://medium.com/@kshitijsrv',
    },
    twitter: {
        baseColor: '#1da1f3',
        handle: '/KshitijSrv',
        profileLink: 'https://www.twitter.com/KshitijSrv',
    },
    github: {
        baseColor: '#282828',
        handle: '/kshitij-srv',
        profileLink: 'https://www.github.com/kshitij-srv',
    },
    devto: {
        baseColor: '#000000',
        handle: '/kshitij',
        profileLink: 'https://www.dev.to/kshitij',
    },
    instagram: {
        baseColor: '#8a23be',
        handle: '/kshitijsrv',
        profileLink: 'https://www.instagram.com/kshitijsrv',
    },
    reddit: {
        baseColor: '#fe4500',
        handle: '/u/kshitijsrv',
        profileLink: 'https://www.reddit.com/user/kshitijsrv',
    },
    goodreads: {
        baseColor: '#7d5024',
        handle: '/kshiitij',
        profileLink: 'https://www.goodreads.com/kshiitij',
    },
};

export default SocialData;
